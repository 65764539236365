import { http, HttpResponse } from 'msw'
import { StatusCodes } from 'http-status-codes'

import { api } from 'app/utils/constants'
import { StatusRefund } from 'app/types/transactions/refund'

import { allSales } from 'app/mocks/data/transactions/sales'

export const createRefund = http.post(
    `${process.env.REACT_APP_API_ADDRESS}/${api.transactions.SALES}/:id/refund`,
    async ({ request, params }) => {
        const data = await request.json()

        const saleFound = allSales.find(
            (sale) => sale.id.toString() === params.id,
        )

        // Not found
        if (!data) {
            return new HttpResponse(null, {
                status: StatusCodes.NOT_FOUND,
            })
        }

        const amount = data.products.reduce(
            (total, product) => total + product.amount,
            0,
        )

        const sale = saleFound

        delete sale.refunds

        return HttpResponse.json({
            ...data,
            id: 999,
            date: new Date().toISOString,
            status: StatusRefund.enum.APPROVED,
            amount,
            author: 999,
            sale,
        })
    },
)
