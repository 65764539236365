import { z } from 'zod'
import { Product } from './product'

/** Enum promotion type.*/
export const TypeDiscount = z.enum(['CART_DISCOUNT'])

/** Discount object returned by the API */
export const Discount = z.object({
    id: z.number(),
    name: z.string(),
    price: z.number(),
    unit: z.string(),
    tva: z.number(),
    products: z.array(Product),
})
