import { createSupplier } from './createSupplier'
import { deleteSupplier } from './deleteSupplier'
import { getAllSuppliers } from './getAllSuppliers'
import { getSupplier } from './getSupplier'
import { updateSupplier } from './updateSupplier'

export const suppliers = [
    createSupplier,
    deleteSupplier,
    getAllSuppliers,
    getSupplier,
    updateSupplier,
]
