import { z } from 'zod'

/** Enum planogram status. */
export const StatusPlanogram = z.enum(['ACTIVE', 'INACTIVE', 'PENDING'])

/** Enum planogram component mode. */
export const ModePlanogram = z.enum([
    'CREATE',
    'DETAILS',
    'PRODUCT_INFO',
    'TECHNICAL_DATAS',
])

/** Enum planogram component actions. */
export const ActionPlanogram = z.enum([
    'MANAGEMENT_TRAY',
    'MANAGEMENT_SELECTION',
    'MAPPING',
    'MOTOR',
    'PRODUCT_CHANGE',
    'SIZE',
    'STOCK',
])

/** Enum planogram selection error */
export const ErrorTypeSelection = z.enum(['EMPTY', 'MAPPING', 'MOTOR', 'NAME'])
