import { http, HttpResponse } from 'msw'

import { allManagers } from 'app/mocks/data/users/managers'
import { api, isNumeric } from 'app/utils/constants'

export const getAllManagers = http.get(
    `${process.env.REACT_APP_API_ADDRESS}/${api.users.managers.LIST}`,
    ({ request }) => {
        const url = new URL(request.url)
        const limit = url.searchParams.get('limit')
        const offset = url.searchParams.get('offset')
        const nameFilter = url.searchParams.get('name__icontains')

        let allManagersFiltered = allManagers

        // Name filter
        if (nameFilter) {
            allManagersFiltered = allManagersFiltered.filter((manager) =>
                manager.name.toLowerCase().includes(nameFilter.toLowerCase()),
            )
        }

        // Pagination
        const limitArray =
            allManagersFiltered.length > limit
                ? offset + limit
                : allManagersFiltered.length

        const results =
            isNumeric(offset) && isNumeric(limit)
                ? allManagersFiltered.slice(Number(offset), limitArray)
                : allManagersFiltered

        const next =
            isNumeric(offset) &&
            isNumeric(limit) &&
            offset + results.length < allManagersFiltered.length
                ? 'again'
                : undefined

        return HttpResponse.json({
            count: allManagersFiltered.length,
            next,
            results: results,
        })
    },
)
