import { http, HttpResponse } from 'msw'

import { allDiagnostics } from 'app/mocks/data/park/diagnostics'
import { api, isNumeric } from 'app/utils/constants'

export const getAllDiagnostics = http.get(
    `${process.env.REACT_APP_API_ADDRESS}/${api.park.DIAGNOSTIC}`,
    ({ request }) => {
        const url = new URL(request.url)
        const limit = url.searchParams.get('limit')
        const offset = url.searchParams.get('offset')
        const machineIdsFilter = url.searchParams.get('machine__id__in')

        let allDiagnosticsFiltered = allDiagnostics

        // Machine filter
        if (!!machineIdsFilter) {
            const machineIdsSplit = machineIdsFilter.split(',')

            allDiagnosticsFiltered = allDiagnosticsFiltered.filter(
                (diagnostic) =>
                    machineIdsSplit.includes(diagnostic.machine.id.toString()),
            )
        }

        // Pagination
        const limitArray =
            allDiagnosticsFiltered.length > limit
                ? offset + limit
                : allDiagnosticsFiltered.length

        const results =
            isNumeric(offset) && isNumeric(limit)
                ? allDiagnosticsFiltered.slice(offset, limitArray)
                : allDiagnosticsFiltered

        const next =
            isNumeric(offset) &&
            isNumeric(limit) &&
            offset + results.length < allDiagnosticsFiltered.length
                ? 'again'
                : undefined

        return HttpResponse.json({
            count: allDiagnosticsFiltered.length,
            next,
            results,
        })
    },
)
