import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { RecoilRoot } from 'recoil'
import { withTranslation } from 'react-i18next'

import { Routes } from './Routes'
import Footer from './shared/Footer'
import Navbar from './shared/Navbar'
import Sidebar from './shared/Sidebar'
import { GlobalSnackbar } from 'app/components/app'
import { urls } from './utils/constants'
import './App.scss'

class App extends Component {
    state = {}

    isFullPageLayout = false
    componentDidMount() {
        this.onRouteChanged()
    }

    render() {
        let navbarComponent = !this.state.isFullPageLayout ? <Navbar /> : ''
        let sidebarComponent = !this.state.isFullPageLayout ? <Sidebar /> : ''
        let footerComponent = !this.state.isFullPageLayout ? <Footer /> : ''

        return (
            <RecoilRoot>
                <GlobalSnackbar />

                <div className='container-scroller'>
                    {navbarComponent}
                    <div className='container-fluid page-body-wrapper'>
                        {sidebarComponent}
                        <div className='main-panel'>
                            <div className='content-wrapper'>
                                <Routes />
                            </div>
                            {footerComponent}
                        </div>
                    </div>
                </div>
            </RecoilRoot>
        )
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.onRouteChanged()
        }
    }

    onRouteChanged() {
        const { i18n } = this.props
        const body = document.querySelector('body')
        if (this.props.location.pathname === '/layout/RtlLayout') {
            body.classList.add('rtl')
            i18n.changeLanguage('ar')
        } else {
            body.classList.remove('rtl')
        }
        window.scrollTo(0, 0)

        const fullPageLayoutRoutes = [
            urls.auth.LOGIN,
            `${urls.auth.LOGIN}/`,

            urls.auth.IMPERSONATE,
            `${urls.auth.IMPERSONATE}/`,

            urls.ERROR_404,
            `${urls.ERROR_404}/`,

            urls.ERROR_500,
            `${urls.ERROR_500}/`,
        ]

        for (let i = 0; i < fullPageLayoutRoutes.length; i++) {
            if (this.props.location.pathname === fullPageLayoutRoutes[i]) {
                this.setState({
                    isFullPageLayout: true,
                })
                document
                    .querySelector('.page-body-wrapper')
                    .classList.add('full-page-wrapper')
                break
            } else {
                if (this.state.isFullPageLayout === true) {
                    this.setState({
                        isFullPageLayout: false,
                    })
                    document
                        .querySelector('.page-body-wrapper')
                        .classList.remove('full-page-wrapper')
                }
            }
        }
    }
}

export default withTranslation()(withRouter(App))
