import * as categories from 'app/mocks/data/users/categories/list'

export const manager1 = {
    id: 1,
    name: 'Sodebo',
    category: categories.category1,
    email: 'manager@sodebo.com',
    phone: '0617253843',
    email_help: 'sav@sodebo.com',
    phone_help: '0136258941',
}

export const manager2 = {
    id: 2,
    name: 'Auchan',
    category: categories.category1,
    email: 'manager@auchan.com',
    phone: '0537650005',
    email_help: 'sav@auchan.com',
}

export const manager3 = {
    id: 3,
    name: 'Leclerc',
    category: categories.category1,
    email: 'manager@leclerc.com',
    phone: '0364548215',
    phone_help: '0236589658',
}

export const manager4 = {
    id: 4,
    name: 'Carrefour',
    category: categories.category1,
    email: 'manager@carrefour.com',
    phone: '0437260704',
    email_help: 'sav@carrefour.com',
}

export const manager5 = {
    id: 5,
    name: 'FarmFresh',
    category: categories.category1,
    email: 'manager@farmfresh.com',
    phone: '022348623',
    phone_help: '0156896325',
}

export const manager6 = {
    id: 6,
    name: 'DailyMart',
    category: categories.category1,
    email: 'manager@dailymart.com',
    phone: '0965259287',
    email_help: 'sav@dailymart.com',
}

export const manager7 = {
    id: 7,
    name: 'GoodEats',
    category: categories.category1,
    email: 'manager@goodeats.com',
    phone: '0118637010',
}

export const manager8 = {
    id: 8,
    name: 'Cristalline',
    category: categories.category1,
    email: 'manager@cristalline.com',
    phone: '0392762882',
    email_help: 'sav@cristalline.com',
    phone_help: '0395856985',
}

export const manager9 = {
    id: 9,
    name: 'Andrea',
    category: categories.category1,
    email: 'manager@andrea.com',
    phone: '0636832148',
    phone_help: '068592145',
}

export const manager10 = {
    id: 10,
    name: 'Le Fournil',
    category: categories.category1,
    email: 'manager@le-fournil.com',
    phone: '0602340193',
    email_help: 'sav@le-fournil.com',
}

export const manager11 = {
    id: 11,
    name: 'Patisserie Orient',
    category: categories.category1,
    email: 'manager@patisserie-orient.com',
    phone: '0721787223',
}

export const manager12 = {
    id: 12,
    name: 'VillageMarket',
    category: categories.category1,
    email: 'manager@villagemarket.com',
    phone: '0846544343',
    email_help: 'sav@villagemarket.com',
}

export const manager13 = {
    id: 13,
    name: 'Café Parisien',
    category: categories.category1,
    email: 'manager@cafe-parisien.com',
    phone: '044970262',
}

export const manager14 = {
    id: 14,
    name: 'Saveurs de France',
    category: categories.category1,
    email: 'manager@saveurs-de-france.com',
    phone: '0801149414',
    email_help: 'sav@saveurs-de-france.com',
}

export const manager15 = {
    id: 15,
    name: 'Creperie du Pays',
    category: categories.category1,
    email: 'manager@creperie-du-pays.com',
    phone: '0604562840',
}

export const manager16 = {
    id: 16,
    name: 'Le Petit Producteur',
    category: categories.category1,
    email: 'manager@le-petit-producteur.com',
    phone: '0161280282',
    email_help: 'sav@le-petit-producteur.com',
    phone_help: '0168258596',
}

export const manager17 = {
    id: 17,
    name: 'La Cave à Vins',
    category: categories.category1,
    email: 'manager@la-cave-a-vins.com',
    phone: '0245429943',
}

export const manager18 = {
    id: 18,
    name: 'La Cave à Fromages',
    category: categories.category1,
    email: 'manager@la-cave-a-fromages.com',
    phone: '025760113',
    email_help: 'sav@la-cave-a-fromages.com',
}

export const manager19 = {
    id: 19,
    name: 'Biscuiterie de Tradition',
    category: categories.category1,
    email: 'manager@biscuiterie-de-tradition.com',
    phone: '0201308779',
}

export const manager20 = {
    id: 20,
    name: 'SuperMarket',
    category: categories.category1,
    email: 'manager@supermarket.com',
    phone: '0664365953',
    email_help: 'sav@supermarket.com',
    phone_help: '0643596985',
}

export const manager21 = {
    id: 21,
    name: 'Intermarché',
    category: categories.category1,
    email: 'manager@intermarche.com',
    phone: '0977476339',
}

export const manager22 = {
    id: 22,
    name: 'Grand Frais',
    category: categories.category1,
    email: 'manager@grand-frais.com',
    phone: '0754823665',
    email_help: 'sav@grand-frais.com',
}
