import { z } from 'zod'

import { ModelRaspberry } from './index'
import { Site } from 'app/types/shared/park/site'

/** Position of the microwave unit in the microwave machine. */
export const PositionMicrowave = z.enum(['1', '2', '3'])

/** Unity of a microwave inside the microwave machine. */
const MicrowaveUnit = z.object({
    /** ID of the microwave unit. */
    id: z.number().positive(),

    /** Position of the microwave unit in the microwave machine. */
    position: PositionMicrowave,

    /** State of the microwave unit. */
    is_enabled: z.boolean(),

    /** Serial number of the microwave unit. */
    serial_number: z.string().min(1),

    /** Brand of the microwave unit. */
    brand: z.string().min(1),

    /** Power of the microwave unit. */
    power: z.number().positive(),

    /** Purchase invoice of the microwave unit. */
    purchase_invoice: z.string().url(),
})

/** The microwave machine that contains multiple microwave units. */
export const Microwave = z.object({
    /** ID of the microwave machine. */
    id: z.number().positive(),

    /** Name of the microwave machine. */
    name: z.string().min(1),

    /** IP address of the microwave machine for the terminal. */
    ip_address: z.string().ip(),

    /** Model of Raspberry inside the microwave machine. */
    model_raspberry: ModelRaspberry,

    /** Serial number of the microwave machine. */
    serial_number: z.string().min(1),

    /** Serial number of the screen inside the microwave machine. */
    serial_number_screen: z.string().min(1),

    /** Serial number of the Raspberry inside the microwave machine. */
    serial_number_raspberry: z.string().min(1),

    /** Array of microwave units inside the microwave machine (1 to 3). */
    microwave_units: MicrowaveUnit.array().min(1).max(3),

    /** Site of the microwave. */
    site: Site,
})
