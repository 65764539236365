import * as managers from 'app/mocks/data/users/managers/list'
import * as products from 'app/mocks/data/products/products/list'
import * as terminals from 'app/mocks/data/machines/linked/terminals'
import * as fridgesWeight from 'app/mocks/data/machines/linked/fridgesWeight'
import { StatusDisputeSale } from 'app/types/transactions/saleStandard'

export const sale1 = {
    id: 1,
    date_time: '2024-05-12T14:44:40+02:00',
    client: managers.manager1,
    machine: terminals.terminal1,
    quantity: 10,
    total_purchase_price: 10 * products.product1.purchase_price,
    turnover_without_vat: 10 * products.product1.price,
    turnover_with_vat:
        10 * products.product1.price * (1 + products.product1.tva / 100),
    status_dispute: StatusDisputeSale.enum.CONTACTED,
    cart_discount: {
        id: 1,
        name: 'test',
        price: 5.25,
        discount_amount: 0.34,
        is_participation: true,
        menus: [
            {
                id: 6946,
                name: 'Bibim K-Do Dessert Offert 1',
                price: 13.4,
                unit: '?',
                tva: 5.5,
                employer_participation: false,
                products: [
                    {
                        id: 1,
                        name: 'Ailerons marinés grillés',
                        price: 4.5,
                        price_paid: 3.5,
                        unit: '?',
                        tva: 5.5,
                        dlc: '2024-07-02',
                        client: 35,
                        product: {
                            id: 2,
                            name: 'Ailerons marinés grillés',
                        },
                    },
                    {
                        id: 2,
                        name: 'Bibimbap au magret de canard',
                        price: 9.9,
                        price_paid: 4.5,
                        unit: '?',
                        tva: 5.5,
                        dlc: '2024-07-03',
                        client: 35,
                        product: {
                            id: 3,
                            name: 'Bibimbap au magret de canard',
                        },
                    },
                    {
                        id: 3,
                        name: 'Tiramisu au matcha et biscuit rose de Reims',
                        price: 3.0,
                        price_paid: 4.5,
                        unit: '?',
                        tva: 5.5,
                        dlc: '2024-07-01',
                        client: 35,
                        product: {
                            id: 4,
                            name: 'Tiramisu au matcha et biscuit rose de Reims',
                        },
                    },
                ],
            },
        ],
        discounts: [
            {
                id: 876398,
                name: "Il faut manger aujourd'hui ! :)",
                price: 2.1,
                unit: '€',
                tva: 5.5,
                products: [
                    {
                        id: 4,
                        name: 'Entremet choco vegan au piment',
                        price: 3.0,
                        price_paid: 4.5,
                        unit: '€',
                        tva: 5.5,
                        dlc: '2024-06-29',
                        product: {
                            id: 5,
                            name: 'Entremet choco vegan au piment',
                        },
                    },
                ],
            },
        ],
        products: [
            {
                id: 5,
                name: 'Poulet croustillant pomme-miel',
                price: 4.6,
                price_paid: 4.6,
                unit: '€',
                tva: 5.5,
                dlc: '2024-07-04',
                supplier: 'Bibim Pop',
                client: 35,
                product: {
                    id: 6,
                    name: 'Poulet croustillant pomme-miel',
                },
            },
        ],
    },
    menus: [
        {
            id: 2645,
            name: 'Menu mock miam',
            price: 1.2202809435462496,
            unit: '?',
            tva: 20.0,
            employer_participation: false,
            products: [
                {
                    id: 6,
                    name: 'Juste Pressé Carotte Pomme',
                    price: 3.99,
                    price_paid: 3.42,
                    unit: '?',
                    tva: 20.0,
                    dlc: '2099-12-12',
                    client_serial: 'null',
                    product_id: null,
                    supplier: null,
                    client: 1,
                    product: {
                        id: 7,
                        name: 'Juste Pressé Carotte Pomme',
                    },
                },
                {
                    id: 7,
                    name: 'Xtrem Box Carbonara',
                    price: 4,
                    price_paid: 4,
                    unit: '?',
                    tva: 20.0,
                    dlc: '2099-12-12',
                    client_serial: 'null',
                    product_id: null,
                    supplier: null,
                    client: 1,
                    product: {
                        id: 8,
                        name: 'Xtrem Box Carbonara',
                    },
                },
            ],
        },
    ],
    discounts: [
        {
            id: 26651443,
            name: 'Le super nom de ma promo',
            price: 1.0,
            unit: '€',
            tva: 10.0,
            products: [
                {
                    id: 8,
                    name: 'Xtrem Box Carbonara',
                    price: 4.0,
                    price_paid: 4,
                    unit: '?',
                    tva: 20.0,
                    dlc: '2099-12-12',
                    client_serial: 'null',
                    product_id: null,
                    supplier: null,
                    client: 1,
                    product: {
                        id: 8,
                        name: 'Xtrem Box Carbonara',
                    },
                },
            ],
        },
        {
            id: 26651445,
            name: 'Le super nom de ma promo',
            price: 1.0,
            unit: '€',
            tva: 10.0,
            products: [
                {
                    id: 9,
                    name: 'Kiwi',
                    price: 2,
                    price_paid: 2,
                    unit: '€',
                    tva: 10.0,
                    dlc: '2023-05-13',
                    client_serial: null,
                    product_id: null,
                    supplier: null,
                    client: null,
                    product: {
                        id: 9,
                        name: 'Kiwi',
                    },
                },
            ],
        },
        {
            id: 26651446,
            name: 'Le super nom de ma promo',
            price: 1.0,
            unit: '€',
            tva: 10.0,
            products: [
                {
                    id: 10,
                    name: 'Kiwi',
                    price: 3.04,
                    price_paid: 1.95,
                    unit: '€',
                    tva: 10.0,
                    dlc: '2023-05-13',
                    client_serial: null,
                    product_id: null,
                    supplier: null,
                    client: null,
                    product: {
                        id: 9,
                        name: 'Kiwi',
                    },
                },
            ],
        },
        {
            id: 26651447,
            name: 'Le super nom de ma promo',
            price: 1.0,
            unit: '€',
            tva: 10.0,
            products: [
                {
                    id: 11,
                    name: 'Kiwi',
                    price: 3.04,
                    price_paid: 1.95,
                    unit: '€',
                    tva: 10.0,
                    dlc: '2023-05-13',
                    client_serial: null,
                    product_id: null,
                    supplier: null,
                    client: null,
                    product: {
                        id: 9,
                        name: 'Kiwi',
                    },
                },
            ],
        },
    ],
    products: [
        {
            ...products.product1,
            id: 12,
            price_paid: 7,
            product: products.product1,
        },
    ],
    refunds: [],
}

export const sale2 = {
    id: 2,
    date_time: '2024-05-13T14:44:40+02:00',
    client: managers.manager1,
    machine: fridgesWeight.fridgeWeight1,
    quantity: 5,
    total_purchase_price: 5 * products.product2.purchase_price,
    turnover_without_vat: 5 * products.product2.price,
    turnover_with_vat:
        5 * products.product2.price * (1 + products.product2.tva / 100),
    products: [products.product2],
    status_dispute: StatusDisputeSale.enum.REFUNDED,
}

export const sale3 = {
    id: 3,
    date_time: '2024-05-18T14:44:40+02:00',
    client: managers.manager1,
    machine: fridgesWeight.fridgeWeight1,
    quantity: 12,
    total_purchase_price: 12 * products.product1.purchase_price,
    turnover_without_vat: 12 * products.product1.price,
    turnover_with_vat:
        12 * products.product1.price * (1 + products.product1.tva / 100),
    products: [products.product1],
    status_dispute: StatusDisputeSale.enum.DISPUTED,
}

export const sale4 = {
    id: 4,
    date_time: '2024-05-13T14:44:40+02:00',
    client: managers.manager1,
    machine: terminals.terminal1,
    quantity: 7,
    total_purchase_price: 7 * products.product3.purchase_price,
    turnover_without_vat: 7 * products.product3.price,
    turnover_with_vat:
        7 * products.product3.price * (1 + products.product5.tva / 100),
    products: [products.product3],
    status_dispute: StatusDisputeSale.enum.IN_PROGRESS,
}

export const sale5 = {
    id: 5,
    date_time: '2024-05-15T14:44:40+02:00',
    client: managers.manager1,
    machine: terminals.terminal1,
    quantity: 15,
    total_purchase_price: 15 * products.product4.purchase_price,
    turnover_without_vat: 15 * products.product4.price,
    turnover_with_vat:
        15 * products.product4.price * (1 + products.product4.tva / 100),
    products: [products.product4],
    status_dispute: StatusDisputeSale.enum.REFUSED,
}

export const sale6 = {
    id: 6,
    date_time: '2024-05-15T14:44:40+02:00',
    client: managers.manager1,
    machine: fridgesWeight.fridgeWeight2,
    quantity: 10,
    total_purchase_price: 10 * products.product4.purchase_price,
    turnover_without_vat: 10 * products.product4.price,
    turnover_with_vat:
        10 * products.product4.price * (1 + products.product4.tva / 100),
    products: [products.product4],
}

export const sale7 = {
    id: 7,
    date_time: '2024-05-18T14:44:40+02:00',
    client: managers.manager1,
    machine: terminals.terminal1,
    quantity: 24,
    total_purchase_price: 24 * products.product5.purchase_price,
    turnover_without_vat: 24 * products.product5.price,
    turnover_with_vat:
        24 * products.product5.price * (1 + products.product5.tva / 100),
    products: [products.product5],
}

export const sale8 = {
    id: 8,
    date_time: '2024-05-23T14:44:40+02:00',
    client: managers.manager1,
    machine: fridgesWeight.fridgeWeight1,
    quantity: 60,
    total_purchase_price: 60 * products.product5.purchase_price,
    turnover_without_vat: 60 * products.product5.price,
    turnover_with_vat:
        60 * products.product5.price * (1 + products.product5.tva / 100),
    products: [products.product5],
}

export const sale9 = {
    id: 9,
    date_time: '2024-05-22T14:44:40+02:00',
    client: managers.manager1,
    machine: fridgesWeight.fridgeWeight1,
    quantity: 35,
    total_purchase_price: 35 * products.product3.purchase_price,
    turnover_without_vat: 35 * products.product3.price,
    turnover_with_vat:
        35 * products.product3.price * (1 + products.product5.tva / 100),
    products: [products.product3],
}

export const sale10 = {
    id: 10,
    date_time: '2024-05-21T14:44:40+02:00',
    client: managers.manager1,
    machine: terminals.terminal1,
    quantity: 25,
    total_purchase_price: 25 * products.product4.purchase_price,
    turnover_without_vat: 25 * products.product4.price,
    turnover_with_vat:
        25 * products.product4.price * (1 + products.product4.tva / 100),
    products: [products.product4],
}

export const sale11 = {
    id: 11,
    date_time: '2024-05-03T15:00:40+02:00',
    client: managers.manager1,
    machine: terminals.terminal1,
    quantity: 14,
    total_purchase_price: 14 * products.product4.purchase_price,
    turnover_without_vat: 14 * products.product4.price,
    turnover_with_vat:
        14 * products.product4.price * (1 + products.product4.tva / 100),
    products: [products.product4],
}

export const sale12 = {
    id: 12,
    date_time: '2024-05-17T14:44:40+02:00',
    client: managers.manager1,
    machine: fridgesWeight.fridgeWeight2,
    quantity: 22,
    total_purchase_price: 22 * products.product5.purchase_price,
    turnover_without_vat: 22 * products.product5.price,
    turnover_with_vat:
        22 * products.product5.price * (1 + products.product5.tva / 100),
    products: [products.product5],
}

export const sale13 = {
    id: 13,
    date_time: '2024-05-13T14:12:40+02:00',
    client: managers.manager1,
    machine: fridgesWeight.fridgeWeight1,
    quantity: 45,
    total_purchase_price: 45 * products.product1.purchase_price,
    turnover_without_vat: 45 * products.product1.price,
    turnover_with_vat:
        45 * products.product1.price * (1 + products.product1.tva / 100),
    products: [products.product1],
}

export const sale14 = {
    id: 14,
    client: managers.manager1,
    machine: fridgesWeight.fridgeWeight1,
    date_time: '2024-05-10T13:44:40+02:00',
    quantity: 20,
    total_purchase_price: 20 * products.product2.purchase_price,
    turnover_without_vat: 20 * products.product2.price,
    turnover_with_vat:
        20 * products.product2.price * (1 + products.product2.tva / 100),
    products: [products.product2],
}

export const sale15 = {
    id: 15,
    date_time: '2024-05-10T14:00:40+02:00',
    client: managers.manager1,
    machine: terminals.terminal1,
    quantity: 10,
    total_purchase_price: 10 * products.product2.purchase_price,
    turnover_without_vat: 10 * products.product2.price,
    turnover_with_vat:
        10 * products.product2.price * (1 + products.product2.tva / 100),
    products: [products.product2],
}

export const sale16 = {
    id: 16,
    date_time: '2024-05-09T14:44:40+02:00',
    client: managers.manager3,
    machine: fridgesWeight.fridgeWeight1,
    quantity: 98,
    total_purchase_price: 98 * products.product4.purchase_price,
    turnover_without_vat: 98 * products.product4.price,
    turnover_with_vat:
        98 * products.product4.price * (1 + products.product4.tva / 100),
    products: [products.product4],
}

export const sale17 = {
    id: 17,
    date_time: '2024-05-05T14:44:40+02:00',
    client: managers.manager1,
    machine: fridgesWeight.fridgeWeight2,
    quantity: 36,
    total_purchase_price: 36 * products.product3.purchase_price,
    turnover_without_vat: 36 * products.product3.price,
    turnover_with_vat:
        36 * products.product3.price * (1 + products.product5.tva / 100),
    products: [products.product3],
}

export const sale18 = {
    id: 18,
    date_time: '2024-05-11T14:44:40+02:00',
    client: managers.manager1,
    machine: fridgesWeight.fridgeWeight2,
    quantity: 22,
    total_purchase_price: 22 * products.product1.purchase_price,
    turnover_without_vat: 22 * products.product1.price,
    turnover_with_vat:
        22 * products.product1.price * (1 + products.product1.tva / 100),
    products: [products.product1],
}

export const sale19 = {
    id: 19,
    date_time: '2024-05-11T14:00:40+02:00',
    client: managers.manager1,
    machine: terminals.terminal1,
    quantity: 13,
    total_purchase_price: 13 * products.product5.purchase_price,
    turnover_without_vat: 13 * products.product5.price,
    turnover_with_vat:
        13 * products.product5.price * (1 + products.product5.tva / 100),
    products: [products.product5],
}
