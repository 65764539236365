import { z } from 'zod'
import { Menu } from './menu'
import { Discount } from './discount'
import { Product } from './product'

/** Cart discount object returned by the API */
export const CartDiscount = z.object({
    id: z.number(),
    name: z.string(),
    price: z.number(),
    discount_amount: z.number(),
    is_participation: z.boolean(),
    menus: z.array(Menu),
    discounts: z.array(Discount),
    products: z.array(Product),
})
