import { http, HttpResponse } from 'msw'
import { StatusCodes } from 'http-status-codes'

import { allSales } from 'app/mocks/data/transactions/sales'

import { api } from 'app/utils/constants'
import { StatusDisputeSale } from 'app/types/transactions/saleStandard'

export const contact = http.post(
    `${process.env.REACT_APP_API_ADDRESS}/${api.transactions.SALES}/:id/contact`,
    async ({ params }) => {
        const { id } = params

        const saleFound = allSales.find((sale) => sale.id.toString() === id)

        // Not found
        if (!saleFound) {
            return new HttpResponse(null, {
                status: StatusCodes.NOT_FOUND,
            })
        }

        return HttpResponse.json({
            ...saleFound,
            status_dispute: StatusDisputeSale.enum.CONTACTED,
        })
    },
)
