import { http, HttpResponse } from 'msw'
import { StatusCodes } from 'http-status-codes'

import { allMicrowaves } from 'app/mocks/data/machines'
import { api } from 'app/utils/constants'

export const updateMicrowave = http.patch(
    `${process.env.REACT_APP_API_ADDRESS}/${api.machines.MICROWAVES}/:id`,
    async ({ request, params }) => {
        const data = await request.json()
        const { id } = params

        const microwaveFound = allMicrowaves.find(
            (machine) => machine.id.toString() === id,
        )

        // Wrong data
        if (!data) {
            return new HttpResponse(null, {
                status: StatusCodes.UNPROCESSABLE_ENTITY,
            })
        }

        // Not found
        if (!microwaveFound) {
            return new HttpResponse(null, {
                status: StatusCodes.NOT_FOUND,
            })
        }

        const microwaveToReturn = {
            ...microwaveFound,
            ...data,
            site: microwaveFound.site,
        }

        return HttpResponse.json(microwaveToReturn)
    },
)
