import { http, HttpResponse } from 'msw'
import { StatusCodes } from 'http-status-codes'

import { allUsersKonsole } from 'app/mocks/data/users/konsole'
import { api } from 'app/utils/constants'

export const deleteUserKonsole = http.delete(
    `${process.env.REACT_APP_API_ADDRESS}/${api.users.KONSOLE}:id/`,
    ({ params }) => {
        const { id } = params

        const userKonsoleFound = allUsersKonsole.find(
            (user) => user.id.toString() === id,
        )

        // Not found
        if (!userKonsoleFound) {
            return new HttpResponse(null, {
                status: StatusCodes.NOT_FOUND,
            })
        }

        return new HttpResponse(null, {
            status: StatusCodes.NO_CONTENT,
        })
    },
)
