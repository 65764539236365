import { createService } from './createService'
import { deleteService } from './deleteService'
import { getAllServices } from './getAllServices'
import { getService } from './getService'
import { updateService } from './updateService'

export const services = [
    createService,
    deleteService,
    getAllServices,
    getService,
    updateService,
]
