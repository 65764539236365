import { http, HttpResponse } from 'msw'
import { StatusCodes } from 'http-status-codes'

import { allClients } from 'app/mocks/data/park/clients'
import { api } from 'app/utils/constants'

export const getClient = http.get(
    `${process.env.REACT_APP_API_ADDRESS}/${api.park.CLIENTS}:id/`,
    ({ params }) => {
        const { id } = params

        const clientFound = allClients.find(
            (client) => client.id.toString() === id,
        )

        // Not found
        if (!clientFound) {
            return new HttpResponse(null, {
                status: StatusCodes.NOT_FOUND,
            })
        }

        return HttpResponse.json(clientFound)
    },
)
