export const rights = {
    machineRelation: {
        CREATE: 'add_machinegrid',
        DELETE: 'delete_machinegrid',
        READ: 'view_machinegrid',
        UPDATE: 'change_machinegrid',
    },

    planogram: {
        CREATE: 'add_machinegriditem',
        DELETE: 'delete_machinegriditem',
        READ: 'view_machinegriditem',
        UPDATE: 'change_machinegriditem',
    },
}
