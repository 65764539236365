import { http, HttpResponse } from 'msw'
import { StatusCodes } from 'http-status-codes'

import { allSales } from 'app/mocks/data/transactions/sales'
import { api } from 'app/utils/constants'

export const getSale = http.get(
    `${process.env.REACT_APP_API_ADDRESS}/${api.transactions.SALES_OLD}:id/`,
    ({ params }) => {
        const { id } = params

        const saleFound = allSales.find((sale) => sale.id.toString() === id)

        // Not found
        if (!saleFound) {
            return new HttpResponse(null, {
                status: StatusCodes.NOT_FOUND,
            })
        }

        return HttpResponse.json(saleFound)
    },
)
