import * as sites from 'app/mocks/data/park/sites/standard'

import { ModelRaspberry } from 'app/types/shared/machine'
import { PositionMicrowave } from 'app/types/shared/machine/microwave'

export const microwave1 = {
    id: 1,
    name: 'Microwave 1',
    ip_address: '192.168.0.1',
    site: sites.siteStandard1,
    model_raspberry: ModelRaspberry.enum.RASPBERRY_PI_5_8GO,
    serial_number: 'M1',
    serial_number_screen: 'S1',
    serial_number_raspberry: 'R1',
    microwave_units: [
        {
            id: 2,
            position: PositionMicrowave.enum[2],
            is_enabled: false,
            serial_number: 'MU2',
            brand: 'LG',
            model: 'Cook',
            purchase_invoice: 'https://e-thik.com',
        },
        {
            id: 1,
            position: PositionMicrowave.enum[1],
            is_enabled: true,
            serial_number: 'MU1',
            brand: 'LG',
            model: 'Cook',
            purchase_invoice: 'https://e-thik.com',
        },
        {
            id: 3,
            position: PositionMicrowave.enum[3],
            is_enabled: true,
            serial_number: 'MU3',
            brand: 'LG',
            model: 'Cook2',
            purchase_invoice: 'https://e-thik.com',
        },
    ],
}

export const microwave2 = {
    id: 2,
    name: 'Microwave 2',
    ip_address: '192.168.0.2',
    site: sites.siteStandard1,
    model_raspberry: ModelRaspberry.enum.RASPBERRY_PI_5_4GO,
    serial_number: 'M2',
    serial_number_screen: 'S2',
    serial_number_raspberry: 'R2',
    microwave_units: [
        {
            id: 4,
            position: PositionMicrowave.enum[1],
            is_enabled: true,
            serial_number: 'MU4',
            brand: 'Samsung',
            model: 'Chief',
            purchase_invoice: 'https://e-thik.com',
        },
    ],
}

export const microwave3 = {
    id: 3,
    name: 'Microwave 3',
    ip_address: '192.168.0.3',
    site: sites.siteStandard1,
    model_raspberry: ModelRaspberry.enum.RASPBERRY_PI_4_8GO,
    serial_number: 'M3',
    serial_number_screen: 'S3',
    serial_number_raspberry: 'R3',
    microwave_units: [
        {
            id: 5,
            position: PositionMicrowave.enum[1],
            is_enabled: false,
            serial_number: 'MU5',
            brand: 'Samsung',
            model: 'Chief',
            purchase_invoice: 'https://e-thik.com',
        },
    ],
}

export const microwave4 = {
    id: 4,
    name: 'Microwave 4',
    ip_address: '192.168.0.4',
    site: sites.siteStandard11,
    model_raspberry: ModelRaspberry.enum.RASPBERRY_PI_5_8GO,
    serial_number: 'M4',
    serial_number_screen: 'S4',
    serial_number_raspberry: 'R4',
    microwave_units: [
        {
            id: 7,
            position: PositionMicrowave.enum[2],
            is_enabled: false,
            serial_number: 'MU7',
            brand: 'Samsung',
            model: 'Master Chief',
            purchase_invoice: 'https://e-thik.com',
        },
        {
            id: 6,
            position: PositionMicrowave.enum[1],
            is_enabled: true,
            serial_number: 'MU6',
            brand: 'Samsung',
            model: 'Master Chief',
            purchase_invoice: 'https://e-thik.com',
        },
    ],
}
