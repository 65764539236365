import { z } from 'zod'
import { Category } from 'app/types/products/category'

/** Konsole user object returned by the API */
export const KonsoleUser = z.object({
    id: z.number().positive(),
    first_name: z.string().min(1),
    last_name: z.string().min(1),
    email: z.string().email(),
})

/** Manager object returned by the API */
export const Manager = z.object({
    id: z.number().positive(),
    name: z.string().min(1),
    category: Category,
    email: z.string().email(),
    phone: z.string().min(1),
    email_help: z.string().min(1),
    phone_help: z.string().min(1),
})
