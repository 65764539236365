import { http, HttpResponse } from 'msw'

import { allCategories } from 'app/mocks/data/users/categories'
import { api, isNumeric } from 'app/utils/constants'

export const getAllCategories = http.get(
    `${process.env.REACT_APP_API_ADDRESS}/${api.users.managers.categories.LIST}`,
    ({ request }) => {
        const url = new URL(request.url)
        const limit = url.searchParams.get('limit')
        const offset = url.searchParams.get('offset')

        // Pagination
        const limitArray =
            allCategories.length > limit ? offset + limit : allCategories.length

        const results =
            isNumeric(offset) && isNumeric(limit)
                ? allCategories.slice(offset, limitArray)
                : allCategories

        const next =
            isNumeric(offset) &&
            isNumeric(limit) &&
            offset + results.length < allCategories.length
                ? 'again'
                : undefined

        return HttpResponse.json({
            count: allCategories.length,
            next,
            results: allCategories,
        })
    },
)
