import * as lockersColdStandard from 'app/mocks/data/machines/standard/lockersCold'
import * as sites from 'app/mocks/data/park/sites/linked'

export const lockerCold1 = {
    ...lockersColdStandard.lockerColdStandard1,
    site: sites.site1,
}

export const lockerCold2 = {
    ...lockersColdStandard.lockerColdStandard2,
    site: sites.site11,
}
