import { http, HttpResponse } from 'msw'
import { StatusCodes } from 'http-status-codes'

import { allServices } from 'app/mocks/data/users/services'
import { api } from 'app/utils/constants'

export const getService = http.get(
    `${process.env.REACT_APP_API_ADDRESS}/${api.users.SERVICES}:id/`,
    ({ params }) => {
        const { id } = params

        const serviceFound = allServices.find(
            (service) => service.id.toString() === id,
        )

        // Not found
        if (!serviceFound) {
            return new HttpResponse(null, {
                status: StatusCodes.NOT_FOUND,
            })
        }

        return HttpResponse.json(serviceFound)
    },
)
