import { http, HttpResponse } from 'msw'

import { allSales } from 'app/mocks/data/transactions/sales'
import { api } from 'app/utils/constants'

// TODO: Finish it in FRO-230
export const getAllSales = http.get(
    `${process.env.REACT_APP_API_ADDRESS}/${api.transactions.SALES_OLD}`,
    () => {
        return HttpResponse.json({
            count: allSales.length,
            next: null,
            results: allSales,
        })
    },
)
