import { createManager } from './createManager'
import { deleteManager } from './deleteManager'
import { getAllManagers } from './getAllManagers'
import { getManager } from './getManager'
import { updateManager } from './updateManager'

export const managers = [
    createManager,
    deleteManager,
    getAllManagers,
    getManager,
    updateManager,
]
