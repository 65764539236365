import { z } from 'zod'

import { CartDiscount } from 'app/types/products/cartDiscount'
import { Product } from 'app/types/products/product'
import { Manager } from 'app/types/users/konsole'
import { Machine } from 'app/types/park/machine'

/** Status of a sale dispute */
export const StatusDisputeSale = z.enum([
    'CONTACTED',
    'DISPUTED',
    'IN_PROGRESS',
    'REFUNDED',
    'REFUSED',
])

/** Standard sale object returned by the API */
export const SaleStandard = z.object({
    id: z.number().positive(),
    date_time: z.string(),
    client: Manager,
    machine: Machine,
    quantity: z.number(),
    total_purchase_price: z.number(),
    turnover_without_vat: z.number(),
    turnover_with_vat: z.number(),
    cart_discount: CartDiscount,
    products: Product.array(),
    status_dispute: StatusDisputeSale.optional(),
})
