import { http, HttpResponse } from 'msw'

import { api, links } from 'app/utils/constants'
import { allManagers } from 'app/mocks/data/users/managers'
import { allClients } from 'app/mocks/data/park/clients'

export const postCreationLink = http.post(
    `${process.env.REACT_APP_API_ADDRESS}/${api.users.konvives.CREATION_LINK}`,
    async ({ request }) => {
        const { client, manager } = await request.json()
        let managerName
        let clientCode
        let link = ''

        // Find the manager
        if (manager) {
            allManagers.some((managerToTest) => {
                const isManager = managerToTest.id === manager

                if (isManager) managerName = managerToTest.name

                return isManager
            })
        }

        // Find the client
        if (client) {
            allClients.some((clientToTest) => {
                const isClient = clientToTest.id === client

                if (isClient) clientCode = clientToTest.name

                return isClient
            })
        }

        // Get the link to send
        if (!!managerName && !!clientCode) {
            link = `${links.KONVIVES}/${managerName}/register?client=${clientCode}`
        }

        if (!!managerName && !clientCode) {
            link = `${links.KONVIVES}/${managerName}/register`
        }

        if (!managerName && !!clientCode) {
            link = `${links.KONVIVES}/${allManagers[0].name}/register?client=${clientCode}`
        }

        if (!managerName && !clientCode) {
            link = `${links.KONVIVES}/${allManagers[0].name}/register`
        }

        return HttpResponse.json({
            link,
        })
    },
)
