import { z } from 'zod'

import { KonsoleUser } from 'app/types/users/konsole'
import { Product } from 'app/types/products/product'
import { SaleStandard } from 'app/types/transactions/saleStandard'

/** Enum type for refund type */
export const TypeRefund = z.enum(['INFORMATION', 'RECREDITATION'])

/** Refund status available. */
export const StatusRefund = z.enum(['APPROVED', 'IN_PROGRESS', 'REFUSED'])

/** Product object with an amount */
export const ProductRefund = Product.pick({
    id: true,
}).extend({
    amount: z.number().positive(),
})

/** Refund object sent to the API to make a refund */
export const RefundPayload = z.object({
    type: TypeRefund,
    reason: z.string().min(1),
    products: ProductRefund.array().min(1),
})

/** Refund object returned by the API after a refund is made */
export const Refund = z.object({
    id: z.number().positive(),
    date: z.string().min(1),
    type: TypeRefund,
    status: StatusRefund,
    amount: z.number().positive(),
    author: KonsoleUser,
    reason: z.string().min(1),
    sale: SaleStandard.shape.id,
    products: Product.array(),
    previous_credit: z.number().positive(),
})
