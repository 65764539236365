import * as sitesStandard from './standard'
import * as fridgesWeightStandard from 'app/mocks/data/machines/standard/fridgesWeight'
import * as lockersColdStandard from 'app/mocks/data/machines/standard/lockersCold'
import * as lockersDryStandard from 'app/mocks/data/machines/standard/lockersDry'
import * as distributorsStandard from 'app/mocks/data/machines/standard/distributors'
import * as terminalsStandard from 'app/mocks/data/machines/standard/terminals'

export const site1 = {
    ...sitesStandard.siteStandard1,
    machines: [
        terminalsStandard.terminalStandard1,
        distributorsStandard.distributorStandard1,
        lockersColdStandard.lockerColdStandard1,
        lockersDryStandard.lockerDryStandard1,
        fridgesWeightStandard.fridgeWeightStandard1,
    ],
    users: [],
}

export const site2 = {
    ...sitesStandard.siteStandard2,
    machines: [fridgesWeightStandard.fridgeWeightStandard2],
    users: [],
}

export const site3 = {
    ...sitesStandard.siteStandard3,
    machines: [],
    users: [],
}

export const site4 = {
    ...sitesStandard.siteStandard4,
    machines: [],
    users: [],
}

export const site5 = {
    ...sitesStandard.siteStandard5,
    machines: [],
    users: [],
}

export const site6 = {
    ...sitesStandard.siteStandard6,
    machines: [],
    users: [],
}

export const site7 = {
    ...sitesStandard.siteStandard7,
    machines: [],
    users: [],
}

export const site8 = {
    ...sitesStandard.siteStandard8,
    machines: [],
    users: [],
}

export const site9 = {
    ...sitesStandard.siteStandard9,
    machines: [],
    users: [],
}

export const site10 = {
    ...sitesStandard.siteStandard10,
    machines: [],
    users: [],
}

export const site11 = {
    ...sitesStandard.siteStandard11,
    machines: [
        terminalsStandard.terminalStandard2,
        distributorsStandard.distributorStandard2,
        lockersColdStandard.lockerColdStandard2,
        lockersDryStandard.lockerDryStandard2,
    ],
    users: [],
}

export const site12 = {
    ...sitesStandard.siteStandard12,
    machines: [fridgesWeightStandard.fridgeWeightStandard3],
    users: [],
}

export const site13 = {
    ...sitesStandard.siteStandard13,
    machines: [],
    users: [],
}

export const site14 = {
    ...sitesStandard.siteStandard14,
    machines: [],
    users: [],
}

export const site15 = {
    ...sitesStandard.siteStandard15,
    machines: [],
    users: [],
}

export const site16 = {
    ...sitesStandard.siteStandard16,
    machines: [
        terminalsStandard.terminalStandard3,
        lockersDryStandard.lockerDryStandard3,
    ],
    users: [],
}

export const site17 = {
    ...sitesStandard.siteStandard17,
    machines: [],
    users: [],
}

export const site18 = {
    ...sitesStandard.siteStandard18,
    machines: [],
    users: [],
}

export const site19 = {
    ...sitesStandard.siteStandard19,
    machines: [],
    users: [],
}

export const site20 = {
    ...sitesStandard.siteStandard20,
    machines: [],
    users: [],
}

export const site21 = {
    ...sitesStandard.siteStandard21,
    machines: [],
    users: [],
}
