import { z } from 'zod'
import { TypeMachine } from 'app/types/shared/machine'

/** Machine object returned by the API */
export const Machine = z.object({
    id: z.number(),
    name: z.string(),
    category: TypeMachine,
    description: z.string(),
    stock_usage_percentage: z.number(),
    number_of_products: z.number(),
    max_products: z.number(),
    provider: z.string(),
    software_version: z.string(),
    build_date: z.string(),
    installation_date: z.string(),
    distributor_volume: z.number(),
    connexion_type: z.string(),
    paiement_method: z.string(),
    private_id: z.string(),
    mode: z.string(),
    enabled: z.boolean(),
    online: z.boolean(),
    is_low_stock: z.boolean(),
    is_high_stock: z.boolean(),
    percentage_stock_alarm: z.number(),
    percentage_high_stock_alarm: z.number(),
    time_high_stock_check: z.string(),
    minimum_credit: z.number(),
    payment_priority: z.string(),
    temperature_unit: z.string(),
    weight_unit: z.string(),
    volume_unit: z.string(),
    currency: z.string(),
    default_locale_customer: z.string(),
    default_locale_intervenor: z.string(),
    has_coin_acceptor: z.boolean(),
    has_banknote_reader: z.boolean(),
    has_printer: z.boolean(),
    has_id_card_reader: z.boolean(),
    has_ask_age_confirmation: z.boolean(),
    user: z.number(),
    components: z.array(),
})
