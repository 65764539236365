import { TypeMachine } from 'app/types/shared/machine'

export const lockerColdStandard1 = {
    id: 501,
    name: 'Locker cold 1 Mock',
    category: TypeMachine.Enum.LOCKER_COLD,
    description: 'implementing the ups switch',
    stock_usage_percentage: 0,
    number_of_products: 0,
    price_table: {
        id: 37,
        name: 'eFrigo Carrefour Bruxelles',
    },
    max_products: 10,
    provider: 'nico front',
    software_version: '2.4',
    build_date: '2024-02-05',
    installation_date: '2024-02-05',
    distributor_volume: 100,
    connexion_type: 'mo',
    paiement_method: 'ts',
    private_id: '8347348',
    mode: 'fe',
    enabled: true,
    online: false,
    is_low_stock: false,
    is_high_stock: false,
    percentage_stock_alarm: 40,
    percentage_high_stock_alarm: 100,
    time_high_stock_check: '12:00:00',
    minimum_credit: 20.0,
    payment_priority: 'CP,CB,TRD',
    paygreen_shop_id: '4334',
    temperature_unit: '°C',
    weight_unit: 'g',
    volume_unit: 'l',
    currency: '€',
    default_locale_customer: 'fr-FR',
    default_locale_intervenor: 'fr-FR',
    has_coin_acceptor: false,
    has_banknote_reader: false,
    has_printer: true,
    has_id_card_reader: false,
    has_ask_age_confirmation: false,
    user: 177,
    components: [],
}

export const lockerColdStandard2 = {
    id: 502,
    name: 'Locker cold 2 Mock',
    category: TypeMachine.Enum.LOCKER_COLD,
    description: 'implementing the ups switch',
    stock_usage_percentage: 0,
    number_of_products: 0,
    price_table: {
        id: 37,
        name: 'eFrigo Carrefour Bruxelles',
    },
    max_products: 10,
    provider: 'nico front',
    software_version: '2.4',
    build_date: '2024-02-05',
    installation_date: '2024-02-05',
    distributor_volume: 100,
    connexion_type: 'mo',
    paiement_method: 'ts',
    private_id: '8347348',
    mode: 'fe',
    enabled: true,
    online: false,
    is_low_stock: false,
    is_high_stock: false,
    percentage_stock_alarm: 40,
    percentage_high_stock_alarm: 100,
    time_high_stock_check: '12:00:00',
    minimum_credit: 20.0,
    payment_priority: 'CP,CB,TRD',
    paygreen_shop_id: '4334',
    temperature_unit: '°C',
    weight_unit: 'g',
    volume_unit: 'l',
    currency: '€',
    default_locale_customer: 'fr-FR',
    default_locale_intervenor: 'fr-FR',
    has_coin_acceptor: false,
    has_banknote_reader: false,
    has_printer: true,
    has_id_card_reader: false,
    has_ask_age_confirmation: false,
    user: 177,
    components: [],
}
