import { z } from 'zod'
import { Product } from './product'

/** Menu object returned by the API */
export const Menu = z.object({
    id: z.number(),
    name: z.string(),
    price: z.number(),
    unit: z.string(),
    tva: z.number(),
    employer_participation: z.boolean(),
    products: z.array(Product),
})
