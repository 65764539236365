import { http, HttpResponse } from 'msw'
import { StatusCodes } from 'http-status-codes'

import { api } from 'app/utils/constants'

export const createUserKonsole = http.post(
    `${process.env.REACT_APP_API_ADDRESS}/${api.users.KONSOLE}`,
    async (request) => {
        const data = await request.request.json()

        // Wrong data
        if (!data) {
            return new HttpResponse(null, {
                status: StatusCodes.UNPROCESSABLE_ENTITY,
            })
        }

        const userKonsoleCreated = {
            id: 999,
            ...data,
        }

        return HttpResponse.json(userKonsoleCreated, {
            status: StatusCodes.CREATED,
        })
    },
)
