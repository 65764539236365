import { z } from 'zod'
import { Category } from 'app/types/products/category'

/** Product object returned by the API */
export const Product = z.object({
    id: z.number().positive(),
    name: z.string().min(1),
    price: z.number().positive(),
    category: Category,
    subcategory: Category,
    allergens: Category.array(),
    purchase_price: z.number().positive(),
    valid_number_of_days: z.number().positive(),
    tva: z.number().positive(),
    description: z.string().min(1),
    ean: z.string().min(1),
    client_serial: z.string().min(1),
    photo: z.string().min(1),
    packaging_photo: z.string().min(1),
    weight: z.number().positive(),
    is_sold_in_lots: z.boolean(),
    disabled: z.boolean(),
    luncheon_vouchers: z.boolean(),
    energy_kj: z.number().positive(),
    energy_kcal: z.number().positive(),
    carbs: z.number().positive(),
    sugars: z.number().positive(),
    fibers: z.number().positive(),
    proteins: z.number().positive(),
    salt: z.number().positive(),
    needs_cooking: z.boolean(),
    mass_measure_1: z.number().positive(),
    mass_measure_2: z.number().positive(),
    mass_measure_3: z.number().positive(),
    mass_measure_4: z.number().positive(),
    mass_measure_5: z.number().positive(),
    age_limit: z.boolean(),
})
