import * as lockersColdStandard from 'app/mocks/data/machines/standard/lockersCold'

export const lockerColdDiagnostic1 = {
    machine: {
        id: lockersColdStandard.lockerColdStandard1.id,
        type: lockersColdStandard.lockerColdStandard1.category,
    },
    internet: {
        is_online: true,
        last_loss_connection: '2022-12-27 08:26:49.219717',
        last_heartbeat_connection: '2022-12-27 08:26:49.219717',
    },
}

export const lockerColdDiagnostic2 = {
    machine: {
        id: lockersColdStandard.lockerColdStandard2.id,
        type: lockersColdStandard.lockerColdStandard2.category,
    },
    internet: {
        is_online: true,
        last_loss_connection: '2022-12-27 08:26:49.219717',
        last_heartbeat_connection: '2022-12-27 08:26:49.219717',
    },
}
