import {
    Button,
    DropZone as DropZoneAria,
    FileTrigger,
} from 'react-aria-components'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { FaFileCsv, FaRegFilePdf } from 'react-icons/fa6'
import { MdAttachFile, MdRemove } from 'react-icons/md'
import { SiMicrosoftexcel } from 'react-icons/si'

import { Flex } from 'app/ui/Flex'
import { joinStyles, palette, various } from 'app/utils/theme'
import { mimeTypes } from 'app/utils/constants'

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        margin: various.margin,
    },

    dropZone: {
        width: '250px',
        height: '100px',
        margin: '0 auto',
        border: `dashed 1px ${palette.grey[60]}`,
        borderRadius: various.borderRadius,
        '&[data-hovered]': {
            borderColor: palette.primary.main,
            backgroundColor: `hsla(${palette.primaryRaw.main}, 0.1)`,
        },
        '&[data-drop-target]': {
            borderColor: palette.primary.main,
            backgroundColor: `hsla(${palette.primaryRaw.main}, 0.1)`,
        },
    },

    buttonChooseFiles: {
        width: '100%',
        height: '100%',
        padding: '0',
        backgroundColor: 'unset',
        border: 'unset',
        borderRadius: various.borderRadius,
    },

    fileGroup: {
        position: 'relative',
    },

    file: {
        maxWidth: '100%',
        height: '80px',
    },

    preview: {
        marginTop: various.margin,
    },

    delete: {
        position: 'absolute',
        right: '0',
        margin: '5px',
        color: palette.text.primary,
        backgroundColor: palette.error.main,
        border: 'unset',
        borderRadius: '50%',
    },
}))

export const DropZone = ({
    className,
    files,
    setFiles,
    acceptedFileTypes,
    allowsMultiple,
    ...properties
}) => {
    const classes = useStyles()
    const { t } = useTranslation()

    /** Know if the MIME type is covered or not. */
    const isMiscellaneous = (type) => {
        if (
            type.startsWith('image') ||
            type === mimeTypes.CSV ||
            type === mimeTypes.EXCEL ||
            type === mimeTypes.PDF
        ) {
            return false
        }

        return true
    }

    /** Manage the drop operation depending on the accepted MIME types. */
    const handleDropOperation = (types) => {
        console.log(types)
        for (const type of [...types.types]) {
            const isTypeInclude = acceptedFileTypes.includes(type)

            if (!isTypeInclude) {
                return 'cancel'
            }
        }

        return 'copy'
    }

    /** Manage the dropped files. */
    const onDrop = async (event) => {
        const filesTemporary = []

        for (const file of event.items) {
            const fileLoaded = await file.getFile()

            filesTemporary.push(fileLoaded)
        }

        setFiles((oldFiles) => [...oldFiles, ...filesTemporary])
    }

    /** Delete the file depending on the file index. */
    const handleDelete = (index) => {
        const newFiles = structuredClone(files)

        newFiles.splice(index, 1)

        setFiles(newFiles)
    }

    return (
        <div className={classes.root}>
            {/* Drop zone */}
            <DropZoneAria
                className={joinStyles(classes.dropZone, className)}
                getDropOperation={(types) => handleDropOperation(types)}
                onDrop={async (event) => {
                    await onDrop(event)
                }}
                {...properties}
            >
                {/* Button to open the file selection */}
                <FileTrigger
                    acceptedFileTypes={acceptedFileTypes}
                    allowsMultiple={allowsMultiple}
                >
                    <Button className={classes.buttonChooseFiles}>
                        {t('dropFileHere')}
                    </Button>
                </FileTrigger>
            </DropZoneAria>

            {/* Previews */}
            <Flex className={classes.preview}>
                {files.map((file, index) => (
                    <div
                        key={index}
                        className={classes.fileGroup}
                    >
                        {/* Image */}
                        {file.type.startsWith('image') && (
                            <img
                                className={classes.file}
                                src={URL.createObjectURL(file)}
                                alt='Preview'
                            />
                        )}

                        {/* CSV */}
                        {file.type === mimeTypes.CSV && (
                            <FaFileCsv className={classes.file} />
                        )}

                        {/* Excel */}
                        {file.type === mimeTypes.EXCEL && (
                            <SiMicrosoftexcel className={classes.file} />
                        )}

                        {/* PDF */}
                        {file.type === mimeTypes.PDF && (
                            <FaRegFilePdf className={classes.file} />
                        )}

                        {/* Other */}
                        {isMiscellaneous(file.type) && (
                            <MdAttachFile className={classes.file} />
                        )}

                        {/* Remove button */}
                        <Button
                            className={classes.delete}
                            onPress={() => handleDelete(index)}
                        >
                            <MdRemove />
                        </Button>
                    </div>
                ))}
            </Flex>
        </div>
    )
}
