import { createUserKonsole } from './createUserKonsole'
import { deleteUserKonsole } from './deleteUserKonsole'
import { getAllUsersKonsole } from './getAllUsersKonsole'
import { getUserKonsole } from './getUserKonsole'
import { updateUserKonsole } from './updateUserKonsole'

export const usersKonsole = [
    createUserKonsole,
    deleteUserKonsole,
    getAllUsersKonsole,
    getUserKonsole,
    updateUserKonsole,
]
