import * as fridgesWeight from 'app/mocks/data/machines/linked/fridgesWeight'
import * as distributors from 'app/mocks/data/machines/linked/distributors'
import * as lockersCold from 'app/mocks/data/machines/linked/lockersCold'
import * as lockersDry from 'app/mocks/data/machines/linked/lockersDry'

import { StatusPlanogram } from 'app/types/park/planogram'
import { OrderMachine } from 'app/types/shared/machine'

export const planogramShort1 = {
    id: 1,
    name: 'Planogram 1',
    status: StatusPlanogram.enum.ACTIVE,
    order: OrderMachine.enum.A,
    machine: distributors.distributor1,
    created_at: '2023-07-17T17:09:30.481764+02:00',
    updated_at: '2024-04-30T16:49:16.690170+02:00',
    address: '0',
    activity_start: '2024-04-30T16:49:16.690057+02:00',
}

export const planogramShort2 = {
    id: 2,
    name: 'Planogram 2',
    status: StatusPlanogram.enum.ACTIVE,
    order: OrderMachine.enum.B,
    machine: lockersCold.lockerCold1,
    created_at: '2023-07-17T17:09:30.481764+02:00',
    updated_at: '2024-04-30T16:49:16.690170+02:00',
    address: '0',
    activity_start: '2024-04-30T16:49:16.690057+02:00',
}

export const planogramShort3 = {
    id: 3,
    name: 'Planogram 3',
    status: StatusPlanogram.enum.ACTIVE,
    order: OrderMachine.enum.A,
    machine: lockersDry.lockerDry1,
    created_at: '2023-07-17T17:09:30.481764+02:00',
    updated_at: '2024-04-30T16:49:16.690170+02:00',
    address: '0',
    activity_start: '2024-04-30T16:49:16.690057+02:00',
    shekel_bay: 'C9VK0e5rTGOqSP6d2nQFCg',
}

export const planogramShort4 = {
    id: 4,
    name: 'Planogram 4',
    status: StatusPlanogram.enum.ACTIVE,
    order: OrderMachine.enum.A,
    machine: fridgesWeight.fridgeWeight1,
    created_at: '2023-07-17T17:09:30.481764+02:00',
    updated_at: '2024-04-30T16:49:16.690170+02:00',
    address: '0',
    activity_start: '2024-04-30T16:49:16.690057+02:00',
}

export const planogramShort5 = {
    id: 5,
    name: 'Planogram 5',
    status: StatusPlanogram.enum.ACTIVE,
    order: OrderMachine.enum.A,
    machine: fridgesWeight.fridgeWeight2,
    created_at: '2023-07-17T17:09:30.481764+02:00',
    updated_at: '2024-04-30T16:49:16.690170+02:00',
    address: '0',
    activity_start: '2024-04-30T16:49:16.690057+02:00',
}

export const planogramShort6 = {
    id: 6,
    name: 'Planogram 6',
    status: StatusPlanogram.enum.ACTIVE,
    order: OrderMachine.enum.A,
    machine: distributors.distributor2,
    created_at: '2023-07-17T17:09:30.481764+02:00',
    updated_at: '2024-04-30T16:49:16.690170+02:00',
    address: '0',
    activity_start: '2024-04-30T16:49:16.690057+02:00',
}

export const planogramShort7 = {
    id: 7,
    name: 'Planogram 7',
    status: StatusPlanogram.enum.ACTIVE,
    order: OrderMachine.enum.B,
    machine: lockersCold.lockerCold2,
    created_at: '2023-07-17T17:09:30.481764+02:00',
    updated_at: '2024-04-30T16:49:16.690170+02:00',
    address: '0',
    activity_start: '2024-04-30T16:49:16.690057+02:00',
}

export const planogramShort8 = {
    id: 8,
    name: 'Planogram 8',
    status: StatusPlanogram.enum.ACTIVE,
    order: OrderMachine.enum.C,
    machine: lockersDry.lockerDry2,
    created_at: '2023-07-17T17:09:30.481764+02:00',
    updated_at: '2024-04-30T16:49:16.690170+02:00',
    address: '0',
    activity_start: '2024-04-30T16:49:16.690057+02:00',
}

export const planogramShort9 = {
    id: 9,
    name: 'Planogram 9',
    status: StatusPlanogram.enum.ACTIVE,
    order: OrderMachine.enum.A,
    machine: fridgesWeight.fridgeWeight3,
    created_at: '2023-07-17T17:09:30.481764+02:00',
    updated_at: '2024-04-30T16:49:16.690170+02:00',
    address: '0',
    activity_start: '2024-04-30T16:49:16.690057+02:00',
}

export const planogramShort10 = {
    id: 10,
    name: 'Planogram 10',
    status: StatusPlanogram.enum.ACTIVE,
    order: OrderMachine.enum.A,
    machine: lockersDry.lockerDry3,
    created_at: '2023-07-17T17:09:30.481764+02:00',
    updated_at: '2024-04-30T16:49:16.690170+02:00',
    address: '0',
    activity_start: '2024-04-30T16:49:16.690057+02:00',
}
