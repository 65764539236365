export const urls = {
    ERROR_404: '/404',
    ERROR_500: '/500',
    HOMEPAGE: '/dashboard',
    PROFILE: '/profile',

    auth: {
        IMPERSONATE: '/auth/impersonate',
        LOGIN: '/auth/login',
        RESET_PASSWORD: '/password-reset',
    },

    operations: {
        DELIVERY_ORDERS: '/operations/delivery-orders',
        DESTOCKING: '/operations/destocking',
        ENCODED_PRODUCTS: '/operations/encoded-products',
        INCIDENTS: '/operations/incidents',
        INDEX: '/operations',
        INTERVENTIONS: '/operations/interventions',
        RABBITMQ_INCIDENTS: '/operations/rabbitmq-incidents',
    },

    machines: {
        ALL: '/park/machines',

        microwaves: {
            CREATE: '/park/machines/microwaves/create',
            DETAILS: '/park/machines/microwaves/:id',
            LIST: '/park/machines/microwaves',
            SETTINGS: '/park/machines/microwaves/:id/settings',
        },

        planograms: {
            INDEX: '/park/planograms',
            CREATE: '/park/planograms/create/:id',
            DETAILS: '/park/planograms/:id',
            PRODUCT_INFO: '/park/planograms/:id/product-info',
            TECHNICAL_DATA: '/park/planograms/:id/technical-data',
        },
    },

    park: {
        INDEX: '/park',

        clients: {
            CREATE: '/park/clients/create',
            DETAILS: '/park/clients/:id',
            LIST: '/park/clients',
        },

        sites: {
            INDEX: '/park/sites',
            DETAILS: '/park/sites/:id',
            MACHINE_MARKETING:
                '/park/sites/:idSite/machine-marketing/:idMachine',
            MACHINE_STOCK: '/park/sites/:idSite/machine-stock/:idMachine',
            TECHNICAL_DATA:
                '/park/sites/:idSite/machine-technical-data/:idMachine',
        },
    },

    products: {
        INDEX: '/products',
        LIST: '/products/list',
        PRICE_TABLES: '/products/price-tables',
        REDUCTIONS: '/products/reductions',
        STATS: '/products/stats',

        suppliers: {
            CREATE: '/products/suppliers/create',
            DETAILS: '/products/suppliers/:id',
            LIST: '/products/suppliers',
        },
    },

    reports: {
        INDEX: '/reports',
        SUPPLY: '/reports/supply',
    },

    settings: {
        CUSTOMISATIONS: '/settings/customisations',
        EMAILS: '/settings/emails',
        FORMS: '/settings/forms',
        INDEX: '/settings',
        TICKETS: '/settings/tickets',
    },

    transactions: {
        BLOCKED_CARDS: '/transactions/blocked-cards',
        INDEX: '/transactions',
        REFILLS: '/transactions/refills',
        REFUNDS: '/transactions/refunds',
        SALES: '/transactions/sales',
    },

    users: {
        DELIVERY_MEN: '/users/delivery-men',
        INDEX: '/users',
        KONVIVES: '/users/konvives',
        PDA: '/users/pda',
        PROFILES: '/users/profiles',
        TECHNICIANS: '/users/technicians',

        konsole: {
            CREATE: '/users/konsole/create',
            DETAILS: '/users/konsole/:id',
            LIST: '/users/konsole',
        },

        managers: {
            CREATE: '/users/managers/create',
            DETAILS: '/users/managers/:id',
            LIST: '/users/managers',
        },

        services: {
            CREATE: '/users/services/create',
            DETAILS: '/users/services/:id',
            LIST: '/users/services',
        },
    },
}
