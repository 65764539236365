import { http, HttpResponse } from 'msw'

import { allClients } from 'app/mocks/data/park/clients'
import { api, isNumeric, queryIntoArrayNumber } from 'app/utils/constants'

export const getAllClients = http.get(
    `${process.env.REACT_APP_API_ADDRESS}/${api.park.CLIENTS}`,
    ({ request }) => {
        const url = new URL(request.url)
        const limit = url.searchParams.get('limit')
        const offset = url.searchParams.get('offset')
        const managerIdsFilter = url.searchParams.get('client__id__in')
        const nameFilter = url.searchParams.get('name__icontains')

        let allClientsFiltered = allClients

        // Manager filter
        if (!!managerIdsFilter) {
            const managerIdsSplit = queryIntoArrayNumber(managerIdsFilter)

            allClientsFiltered = allClientsFiltered.filter((client) =>
                managerIdsSplit.includes(client.client.id),
            )
        }

        // Name filter
        if (!!nameFilter) {
            allClientsFiltered = allClientsFiltered.filter((client) =>
                client.name.toLowerCase().includes(nameFilter.toLowerCase()),
            )
        }

        // Pagination
        const limitArray =
            allClientsFiltered.length > limit
                ? offset + limit
                : allClientsFiltered.length

        const results =
            isNumeric(offset) && isNumeric(limit)
                ? allClientsFiltered.slice(offset, limitArray)
                : allClientsFiltered

        const next =
            isNumeric(offset) &&
            isNumeric(limit) &&
            offset + results.length < allClientsFiltered.length
                ? 'again'
                : undefined

        return HttpResponse.json({
            count: allClientsFiltered.length,
            next,
            results,
        })
    },
)
