import { http, HttpResponse } from 'msw'
import { StatusCodes } from 'http-status-codes'

import { allServices } from 'app/mocks/data/users/services'
import { api } from 'app/utils/constants'

export const updateService = http.patch(
    `${process.env.REACT_APP_API_ADDRESS}/${api.users.SERVICES}:id/`,
    async ({ request, params }) => {
        const data = await request.json()
        const { id } = params

        const serviceFound = allServices.find(
            (service) => service.id.toString() === id,
        )

        // Wrong data
        if (!data) {
            return new HttpResponse(null, {
                status: StatusCodes.UNPROCESSABLE_ENTITY,
            })
        }

        // Not found
        if (!serviceFound) {
            return new HttpResponse(null, {
                status: StatusCodes.NOT_FOUND,
            })
        }

        const serviceUpdated = {
            ...serviceFound,
            ...data,
        }

        return HttpResponse.json(serviceUpdated)
    },
)
