import { http, HttpResponse } from 'msw'

import { allSites } from 'app/mocks/data/park/sites'
import { api, isNumeric, queryIntoArrayNumber } from 'app/utils/constants'

export const getAllSites = http.get(
    `${process.env.REACT_APP_API_ADDRESS}/${api.park.SITES}`,
    ({ request }) => {
        const url = new URL(request.url)
        const limit = url.searchParams.get('limit')
        const offset = url.searchParams.get('offset')
        const managerIdsFilter = url.searchParams.get(
            'customer_name__client__id__in',
        )
        const clientIdsFilter = url.searchParams.get('customer_name__id__in')
        const nameFilter = url.searchParams.get('name__icontains')

        let allSitesFiltered = allSites

        // Manager filter
        if (!!managerIdsFilter) {
            const managerIdsSplit = queryIntoArrayNumber(managerIdsFilter)

            allSitesFiltered = allSitesFiltered.filter((site) =>
                managerIdsSplit.includes(site.customer_name.client.id),
            )
        }

        // Client filter
        if (!!clientIdsFilter) {
            const clientIdsSplit = queryIntoArrayNumber(clientIdsFilter)

            allSitesFiltered = allSitesFiltered.filter((site) =>
                clientIdsSplit.includes(site.customer_name.id),
            )
        }

        // Name filter
        if (!!nameFilter) {
            allSitesFiltered = allSitesFiltered.filter((site) =>
                site.name.toLowerCase().includes(nameFilter.toLowerCase()),
            )
        }

        // Pagination
        const limitArray =
            allSitesFiltered.length > limit
                ? offset + limit
                : allSitesFiltered.length

        const results =
            isNumeric(offset) && isNumeric(limit)
                ? allSitesFiltered.slice(offset, limitArray)
                : allSitesFiltered

        const next =
            isNumeric(offset) &&
            isNumeric(limit) &&
            offset + results.length < allSitesFiltered.length
                ? 'again'
                : undefined

        return HttpResponse.json({
            count: allSitesFiltered.length,
            next,
            results,
        })
    },
)
