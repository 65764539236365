import React, { Component, lazy, Suspense } from 'react'
import jwt_decode from 'jwt-decode'
import { Redirect, Route, Switch } from 'react-router-dom'

import Spinner from 'app/shared/Spinner'
import { urls } from 'app/utils/constants'
import { history } from 'app/utils/app/history'

// Auth
const Login = lazy(() => import('app/pages/auth/login'))
const Impersonate = lazy(() => import('app/pages/auth/impersonate'))
const ResetPassword = lazy(() => import('app/pages/auth/reset-password'))

// Profile
const Profile = lazy(() => import('app/pages/profile'))

// Homepage
const Homepage = lazy(() => import('app/pages'))

// Clients
const ClientList = lazy(() => import('app/pages/park/clients/index'))
const ClientCreate = lazy(() => import('app/pages/park/clients/create'))
const ClientDetails = lazy(() => import('app/pages/park/clients/[id]'))

// Sites
const Sites = lazy(() => import('app/pages/park/sites/index'))
const SitesMachineStock = lazy(
    () => import('app/pages/park/sites/machine-stock'),
)
const SitesMachineTechnicalData = lazy(
    () => import('app/pages/park/sites/machine-technical-data'),
)
const SitesMarketingMachine = lazy(
    () => import('app/pages/park/sites/marketing-machine'),
)

// Machines
const Machines = lazy(() => import('app/pages/park/machines'))

// Microwaves
const MicrowaveList = lazy(() => import('app/pages/park/machines/microwaves'))
const MicrowaveCreate = lazy(
    () => import('app/pages/park/machines/microwaves/create'),
)
const MicrowaveDetails = lazy(
    () => import('app/pages/park/machines/microwaves/[id]'),
)
const MicrowaveSettings = lazy(
    () => import('app/pages/park/machines/microwaves/[id]/settings'),
)

// Planograms
const Planograms = lazy(() => import('app/pages/park/planograms'))
const PlanogramsCreate = lazy(
    () => import('app/pages/park/planograms/create/[id]'),
)
const PlanogramsDetails = lazy(() => import('app/pages/park/planograms/[id]'))
const PlanogramsProductInfo = lazy(
    () => import('app/pages/park/planograms/[id]/product-info'),
)
const PlanogramsTechnicalData = lazy(
    () => import('app/pages/park/planograms/[id]/technical-data'),
)

// Suppliers
const SuppliersList = lazy(() => import('app/pages/products/suppliers/index'))
const SuppliersCreate = lazy(
    () => import('app/pages/products/suppliers/create'),
)
const SuppliersDetails = lazy(() => import('app/pages/products/suppliers/[id]'))

// Products
const Products = lazy(() => import('app/pages/products/list'))

// Price tables
const PriceTables = lazy(() => import('app/pages/products/price-tables'))

// Reductions
const Reductions = lazy(() => import('app/pages/products/reductions'))

// Stats
const Stats = lazy(() => import('app/pages/products/stats'))

// Sales
const Sales = lazy(() => import('app/pages/transactions/sales'))

// Refills
const Refills = lazy(() => import('app/pages/transactions/refills'))

// Refunds
const Refunds = lazy(() => import('app/pages/transactions/refunds'))

// Blocked cards
const BlockedCards = lazy(() => import('app/pages/transactions/blocked-cards'))

// Delivery orders
const DeliveryOrders = lazy(
    () => import('app/pages/operations/delivery-orders'),
)

// Incidents
const Incidents = lazy(() => import('app/pages/operations/incidents'))

// RabbitMQ incidents
const RabbitMQIncidents = lazy(
    () => import('app/pages/operations/rabbitmq-incidents'),
)

// Encoded products
const EncodedProducts = lazy(
    () => import('app/pages/operations/encoded-products'),
)

// Destocking
const Destocking = lazy(() => import('app/pages/operations/destocking'))

// Interventions
const Interventions = lazy(() => import('app/pages/operations/interventions'))

// Supply
const Supply = lazy(() => import('app/pages/reports/supply'))

// Technicians
const Technicians = lazy(() => import('app/pages/users/technicians'))

// Delivery men
const DeliveryMen = lazy(() => import('app/pages/users/delivery-men'))

// Managers
const ManagerList = lazy(() => import('app/pages/users/managers/index'))
const ManagerCreate = lazy(() => import('app/pages/users/managers/create'))
const ManagerDetails = lazy(() => import('app/pages/users/managers/[id]'))

// Services
const ServiceList = lazy(() => import('app/pages/users/services/index'))
const ServiceCreate = lazy(() => import('app/pages/users/services/create'))
const ServiceDetails = lazy(() => import('app/pages/users/services/[id]'))

// Profiles
const Profiles = lazy(() => import('app/pages/users/profiles'))

// Konvives
const Konvives = lazy(() => import('app/pages/users/konvives'))

// Users Konsole
const UsersKonsoleList = lazy(() => import('app/pages/users/konsole/index'))
const UsersKonsoleCreate = lazy(() => import('app/pages/users/konsole/create'))
const UsersKonsoleDetails = lazy(() => import('app/pages/users/konsole/[id]'))

// Users PDA
const UsersPDA = lazy(() => import('app/pages/users/pda'))

// Forms
const Forms = lazy(() => import('app/pages/settings/forms'))

// Emails
const Emails = lazy(() => import('app/pages/settings/emails'))

// Customisation
const Customisations = lazy(() => import('app/pages/settings/customisations'))

// Tickets
const Tickets = lazy(() => import('app/pages/settings/tickets'))

// Error 404
const Error404 = lazy(() => import('app/pages/404'))

// Error 500
const Error500 = lazy(() => import('app/pages/500'))

const GuardedRoute = ({ component: Component, authed, ...properties }) => (
    <Route
        history={history}
        render={(props) =>
            authed === true ? (
                <Component {...props} />
            ) : (
                <Redirect to={urls.auth.LOGIN} />
            )
        }
        {...properties}
    />
)

export const isAuthenticated = () => {
    const token = localStorage.getItem('refresh_token')
    let isExpired = false

    if (token) {
        const decodedToken = jwt_decode(token)
        const dateNow = new Date()

        if (
            decodedToken.exp <
            parseInt(dateNow.getTime().toString().slice(0, -3))
        ) {
            isExpired = true
        }

        return !isExpired
    }

    if (!token) {
        return false
    }
}

export class Routes extends Component {
    render() {
        return (
            <Suspense fallback={<Spinner />}>
                <Switch>
                    {/* Auth */}
                    <Route
                        path={urls.auth.LOGIN}
                        component={Login}
                        exact
                    />

                    <Route
                        path={urls.auth.IMPERSONATE}
                        component={Impersonate}
                        exact
                    />

                    <Route
                        path={urls.auth.RESET_PASSWORD}
                        component={ResetPassword}
                        exact
                    />

                    {/* Profile */}
                    <GuardedRoute
                        path={urls.PROFILE}
                        component={Profile}
                        authed={isAuthenticated()}
                        exact
                    />

                    {/* Homepage */}
                    <GuardedRoute
                        path={urls.HOMEPAGE}
                        component={Homepage}
                        authed={isAuthenticated()}
                        exact
                    />

                    {/* Clients */}
                    <GuardedRoute
                        path={urls.park.clients.LIST}
                        component={ClientList}
                        authed={isAuthenticated()}
                        exact
                    />

                    <GuardedRoute
                        path={urls.park.clients.CREATE}
                        component={ClientCreate}
                        authed={isAuthenticated()}
                        exact
                    />

                    <GuardedRoute
                        path={urls.park.clients.DETAILS}
                        component={ClientDetails}
                        authed={isAuthenticated()}
                        exact
                    />

                    {/* Sites */}
                    <GuardedRoute
                        path={urls.park.sites.INDEX}
                        component={Sites}
                        authed={isAuthenticated()}
                        exact
                    />

                    <GuardedRoute
                        path={urls.park.sites.TECHNICAL_DATA}
                        component={SitesMachineTechnicalData}
                        authed={isAuthenticated()}
                        exact
                    />

                    <GuardedRoute
                        path={urls.park.sites.MACHINE_STOCK}
                        component={SitesMachineStock}
                        authed={isAuthenticated()}
                        exact
                    />

                    <GuardedRoute
                        path={urls.park.sites.MACHINE_MARKETING}
                        component={SitesMarketingMachine}
                        authed={isAuthenticated()}
                        exact
                    />

                    <GuardedRoute
                        path={urls.park.sites.DETAILS}
                        component={Machines}
                        authed={isAuthenticated()}
                        exact
                    />

                    {/* Machines */}
                    <GuardedRoute
                        path={urls.machines.ALL}
                        component={Machines}
                        authed={isAuthenticated()}
                        exact
                    />

                    {/* Microwaves */}
                    <GuardedRoute
                        path={urls.machines.microwaves.LIST}
                        component={MicrowaveList}
                        authed={isAuthenticated()}
                        exact
                    />

                    <GuardedRoute
                        path={urls.machines.microwaves.CREATE}
                        component={MicrowaveCreate}
                        authed={isAuthenticated()}
                        exact
                    />

                    <GuardedRoute
                        path={urls.machines.microwaves.DETAILS}
                        component={MicrowaveDetails}
                        authed={isAuthenticated()}
                        exact
                    />

                    <GuardedRoute
                        path={urls.machines.microwaves.SETTINGS}
                        component={MicrowaveSettings}
                        authed={isAuthenticated()}
                        exact
                    />

                    {/* Planograms */}
                    <GuardedRoute
                        path={urls.machines.planograms.INDEX}
                        component={Planograms}
                        authed={isAuthenticated()}
                        exact
                    />

                    <GuardedRoute
                        path={urls.machines.planograms.CREATE}
                        component={PlanogramsCreate}
                        authed={isAuthenticated()}
                        exact
                    />

                    <GuardedRoute
                        path={urls.machines.planograms.DETAILS}
                        component={PlanogramsDetails}
                        authed={isAuthenticated()}
                        exact
                    />

                    <GuardedRoute
                        path={urls.machines.planograms.PRODUCT_INFO}
                        component={PlanogramsProductInfo}
                        authed={isAuthenticated()}
                        exact
                    />

                    <GuardedRoute
                        path={urls.machines.planograms.TECHNICAL_DATA}
                        component={PlanogramsTechnicalData}
                        authed={isAuthenticated()}
                        exact
                    />

                    {/* Suppliers */}
                    <GuardedRoute
                        path={urls.products.suppliers.LIST}
                        component={SuppliersList}
                        authed={isAuthenticated()}
                        exact
                    />

                    <GuardedRoute
                        path={urls.products.suppliers.CREATE}
                        component={SuppliersCreate}
                        authed={isAuthenticated()}
                        exact
                    />

                    <GuardedRoute
                        path={urls.products.suppliers.DETAILS}
                        component={SuppliersDetails}
                        authed={isAuthenticated()}
                        exact
                    />

                    {/* Products */}
                    <GuardedRoute
                        path={urls.products.LIST}
                        component={Products}
                        authed={isAuthenticated()}
                        exact
                    />

                    {/* Price tables */}
                    <GuardedRoute
                        path={urls.products.PRICE_TABLES}
                        component={PriceTables}
                        authed={isAuthenticated()}
                        exact
                    />

                    {/* Reductions */}
                    <GuardedRoute
                        path={urls.products.REDUCTIONS}
                        component={Reductions}
                        authed={isAuthenticated()}
                        exact
                    />

                    {/* Stats */}
                    <GuardedRoute
                        path={urls.products.STATS}
                        component={Stats}
                        authed={isAuthenticated()}
                        exact
                    />

                    {/* Sales */}
                    <GuardedRoute
                        path={urls.transactions.SALES}
                        component={Sales}
                        authed={isAuthenticated()}
                        exact
                    />

                    {/* Refills */}
                    <GuardedRoute
                        path={urls.transactions.REFILLS}
                        component={Refills}
                        authed={isAuthenticated()}
                        exact
                    />

                    {/* Refunds */}
                    <GuardedRoute
                        path={urls.transactions.REFUNDS}
                        component={Refunds}
                        authed={isAuthenticated()}
                        exact
                    />

                    {/* Blocked cards */}
                    <GuardedRoute
                        path={urls.transactions.BLOCKED_CARDS}
                        component={BlockedCards}
                        authed={isAuthenticated()}
                        exact
                    />

                    {/* Delivery orders */}
                    <GuardedRoute
                        path={urls.operations.DELIVERY_ORDERS}
                        component={DeliveryOrders}
                        authed={isAuthenticated()}
                        exact
                    />

                    {/* Incidents */}
                    <GuardedRoute
                        path={urls.operations.INCIDENTS}
                        component={Incidents}
                        authed={isAuthenticated()}
                        exact
                    />

                    {/* RabbitMQ incidents */}
                    <GuardedRoute
                        path={urls.operations.RABBITMQ_INCIDENTS}
                        component={RabbitMQIncidents}
                        authed={isAuthenticated()}
                        exact
                    />

                    {/* Interventions */}
                    <GuardedRoute
                        path={urls.operations.INTERVENTIONS}
                        component={Interventions}
                        authed={isAuthenticated()}
                        exact
                    />

                    {/* Encoded products */}
                    <GuardedRoute
                        path={urls.operations.ENCODED_PRODUCTS}
                        component={EncodedProducts}
                        authed={isAuthenticated()}
                        exact
                    />

                    {/* Destocking */}
                    <GuardedRoute
                        path={urls.operations.DESTOCKING}
                        component={Destocking}
                        authed={isAuthenticated()}
                        exact
                    />

                    {/* Supply */}
                    <GuardedRoute
                        path={urls.reports.SUPPLY}
                        component={Supply}
                        authed={isAuthenticated()}
                        exact
                    />

                    {/* Technicians */}
                    <GuardedRoute
                        path={urls.users.TECHNICIANS}
                        component={Technicians}
                        authed={isAuthenticated()}
                        exact
                    />

                    {/* Delivery men */}
                    <GuardedRoute
                        path={urls.users.DELIVERY_MEN}
                        component={DeliveryMen}
                        authed={isAuthenticated()}
                        exact
                    />

                    {/* Managers */}
                    <GuardedRoute
                        path={urls.users.managers.LIST}
                        component={ManagerList}
                        authed={isAuthenticated()}
                        exact
                    />

                    <GuardedRoute
                        path={urls.users.managers.CREATE}
                        component={ManagerCreate}
                        authed={isAuthenticated()}
                        exact
                    />

                    <GuardedRoute
                        path={urls.users.managers.DETAILS}
                        component={ManagerDetails}
                        authed={isAuthenticated()}
                        exact
                    />

                    {/* Services */}
                    <GuardedRoute
                        path={urls.users.services.LIST}
                        component={ServiceList}
                        authed={isAuthenticated()}
                        exact
                    />

                    <GuardedRoute
                        path={urls.users.services.CREATE}
                        component={ServiceCreate}
                        authed={isAuthenticated()}
                        exact
                    />

                    <GuardedRoute
                        path={urls.users.services.DETAILS}
                        component={ServiceDetails}
                        authed={isAuthenticated()}
                        exact
                    />

                    {/* Profiles */}
                    <GuardedRoute
                        path={urls.users.PROFILES}
                        component={Profiles}
                        authed={isAuthenticated()}
                        exact
                    />

                    {/* Konvives */}
                    <GuardedRoute
                        path={urls.users.KONVIVES}
                        component={Konvives}
                        authed={isAuthenticated()}
                        exact
                    />

                    {/* Users Konsole */}
                    <GuardedRoute
                        path={urls.users.konsole.LIST}
                        component={UsersKonsoleList}
                        authed={isAuthenticated()}
                        exact
                    />

                    <GuardedRoute
                        path={urls.users.konsole.CREATE}
                        component={UsersKonsoleCreate}
                        authed={isAuthenticated()}
                        exact
                    />

                    <GuardedRoute
                        path={urls.users.konsole.DETAILS}
                        component={UsersKonsoleDetails}
                        authed={isAuthenticated()}
                        exact
                    />

                    {/* Users PDA */}
                    <GuardedRoute
                        path={urls.users.PDA}
                        component={UsersPDA}
                        authed={isAuthenticated()}
                        exact
                    />

                    {/* Forms */}
                    <GuardedRoute
                        path={urls.settings.FORMS}
                        component={Forms}
                        authed={isAuthenticated()}
                        exact
                    />

                    {/* Emails */}
                    <GuardedRoute
                        path={urls.settings.EMAILS}
                        component={Emails}
                        authed={isAuthenticated()}
                    />

                    {/* Customisation */}
                    <GuardedRoute
                        path={urls.settings.CUSTOMISATIONS}
                        component={Customisations}
                        authed={isAuthenticated()}
                        exact
                    />

                    {/* Tickets */}
                    <GuardedRoute
                        path={urls.settings.TICKETS}
                        component={Tickets}
                        authed={isAuthenticated()}
                        exact
                    />

                    {/* Error 404 */}
                    <Route
                        path={urls.ERROR_404}
                        component={Error404}
                        exact
                    />

                    {/* Error 500 */}
                    <Route
                        path={urls.ERROR_500}
                        component={Error500}
                        exact
                    />

                    <Redirect to={urls.HOMEPAGE} />
                </Switch>
            </Suspense>
        )
    }
}
