import { z } from 'zod'

/** Type of machine possible. */
export const TypeMachine = z.enum([
    'DISTRIBUTOR',
    'FRIDGE_RFID',
    'FRIDGE_WEIGHT',
    'LOCKER_COLD',
    'LOCKER_DRY',
    'TERMINAL',
])

/** Machine order available in the machine relation. */
export const OrderMachine = z.enum(['A', 'B', 'C', 'D', 'E', 'F'])

/** Raspberry model available in the machine. */
export const ModelRaspberry = z.enum([
    'RASPBERRY_PI_5_4GO',
    'RASPBERRY_PI_5_8GO',
])
